.event-list-container {
    height: 720px;
    overflow-y: scroll;
}

.event-stat-card {
    padding-left: 50px;
}

.event-list-card-type-label {
    position: absolute;
    font-weight: bolder !important;
    left: -2px;
    font-size: smaller !important;
    bottom: 0;
    transform-origin: 0 0;
    text-transform: uppercase;
    transform: rotate(270deg);
}
